.toggle {
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    // border: 2px solid red;
    display: flex;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    position: relative;

    &::before {
      content: "";
      width: 50px;
      height: 24px;
      background: #e6e8eb;
      border-radius: 50px;
    }
    &::after {
      content: "";
      height: 20px;
      width: 20px;
      background: #ffff;
      border-radius: 50px;
      position: absolute;
      left: 4px;
    }
  }
  input[type="checkbox"]:checked {
    &::after {
      left: unset;
      right: 4px;
      //   background: #5d9efe;
      background: #ffff;
    }
  }
  &-primary {
    input[type="checkbox"]:checked {
      &::before {
        background: #5d9efe;
      }
    }
  }
  &-success {
    input[type="checkbox"]:checked {
      &::before {
        background: #6fc482;
      }
    }
  }
  &-danger {
    input[type="checkbox"]:checked {
      &::before {
        background: #e87882;
      }
    }
  }
  &-warning {
    input[type="checkbox"]:checked {
      &::before {
        //   background: #b4d2fe;
        background: #e8b006;
      }
    }
  }
  &-info {
    input[type="checkbox"]:checked {
      &::before {
        //   background: #b4d2fe;
        background: #0cb8da;
      }
    }
  }
  &-default {
    input[type="checkbox"]:checked {
      &::before {
        //   background: #b4d2fe;
        background: #adb5bd;
      }
    }
  }
}
