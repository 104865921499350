@import "../../assets/css/var";

.status {
  height: 32px;
  display: flex;
  align-items: center;
  font-family: $font-family-2;
  font-size: $base-font-size;
  padding: 4px 10px;
  width: fit-content;
  &-primary {
    background: #e7f1ff;
    color: #5d9efe;
  }
  &-success {
    background: #eaf6ec;
    color: #6fc482;
  }
  &-danger {
    background: #fcebec;
    color: #e87882;
  }
  &-warning {
    background: #fff9e6;
    color: #e8b006;
  }
  &-info {
    background: #e7fafe;
    color: #0cb8da;
  }
  &-default {
    background: #f7f8f8;
    color: #9da5ac;
  }
}
