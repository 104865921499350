@import "../../assets/css/var";

.hero {
  background-color: $background;
  min-height: 380px;
  display: flex;
 

  background-image: url("../../../public/img/hero_bg.svg");
  background-size: cover  ;
  background-repeat: no-repeat;

  &_wrapper{
   padding: 8px;
    // max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    // height: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 24px;
    position: relative;
  }

 

  &-left_content {
    z-index: 1;
    h1 {
      background-image: linear-gradient(to right, #4341bc, #c54ecf);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      max-width: 600px;
    }
  }
  &-right_content{
    img{
      width: calc(100%);
      z-index: 0;
      // box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
    }
  }
}

@media screen and (max-width: $md-width) {

  .hero {
    &_wrapper{
      margin-left: 8px;
      margin-right: 8px;
      width: 100%;
      flex-direction: column-reverse;
      grid-gap: 4px;
    }
    &-right_content{
      img{
       opacity: 0.6;
       max-height: 200px;
        // box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
      }
    }
    &-left_content { 
      h1 {
     font-size: 24px;
      }
    }
  }
}