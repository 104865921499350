@import "../../assets/css/var.scss";
.panel {
  width: 100%;
  min-height: 30vh;
  background: $background;
  border: 1px solid #e7ecf8;
  box-shadow: 0px 2px 4px 1px #ced3d8;
  border-radius: 4px;
  &_header {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: $text-color;
    padding: 8px;
    border-bottom: 2px solid #ced3d8da;
    max-height: 40px;
    position: relative;
    .right_content {
      position: absolute;
      right: 8px;
      top: 4px;
    }
  }
  .panel_content {
    padding: 8px; 

  }
}
