@import "../../assets//css/var";

.input-group {
  position: relative;
  max-height: 48px;
  display: flex;
}
.input-ol {
  height: 48px;
  width: 100%;
  padding: 16px 24px;
  font-size: $base-font-size;
  border: $input-border;
  background: $input-gb;
  color: $text-color;
  &:focus {
    outline: none;
    border: 1px solid rgb(8, 8, 248);
  }
  &:disabled {
    background-color: $sub-menu-hover;
  }
}

.input-group.error {
  input {
    border: 1px solid rgb(214, 8, 8);
  }
}
.input-error {
  display: flex;
  position: absolute;
  bottom: -16px;
  color: red;
  font-size: 12px;
  font-weight: 600;
  font-family: $font-family-2;
  padding: 0px 24px;
}
