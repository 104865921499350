@import "../../assets/css/var.scss";

.profile_google_auth {
  &_wrapper {
    width: calc(100% - 48px);
    margin: 24px; 
    color: $text-color;
  }

  &_content {
    max-width: 504px;
    margin-left: auto;
    margin-right: auto;
  }
}

.auth_qr {
  &_wrapper {
    display: flex;
    flex-direction: row;
    padding: 16px;
    width: 100%;
    min-height: 160px;
  }

  &_hint {
    max-width: 288px;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    font-size: 14px;
    color: $text-color;

  }

  &_code {
    max-width: 144px;
    max-height: 144px;
    min-width: 80px;
    width: calc(100%);
    height: auto;
    // background: #eff2f9;
    margin: 8px;
    float: right;
    position: relative;

    img {
      width: calc(100%);
      height: auto;
      min-width: 100px;
    }
  }
}

.auth_secret {
  &_wrapper {
    padding: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_details {
    flex: 5;
    display: flex;
    max-width: 390px;
    width: 100%;

    input {
      height: 42px;
      // flex: 4;
      padding: 8px;
      background: #f1f5f9;
      border-radius: 4px;
      border: none;
      outline: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #343a40;
      text-align: center;
    }

    button {
      // flex: 1;
      height: 42px;
    }
  }
}

.auth_input {
  &_wrapper {
    padding: 8px;
    width: 100%;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
}

.auth_button {
  &_wrapper {
    max-width: 256px;
    width: 100%;
    padding: 16px;

    button {
      font-size: 18px;
    }
  }
}

.back_steps {
  width: 30px;
  cursor: pointer;
}


.copyable-text-field{
  .input-group {
    flex: 1 1;
  }
}


@media screen and (max-width: 480px) {
  .custom_input_group.sl__input {
    min-width: unset;
  }
}
