@import "../../assets/css/var";

.task-card {
  display: flex;
  box-shadow: 0px 5px 21px -5px #cdd1e1;
  // box-shadow: 0px 12px 24px 0px #0000000D;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: $container-bg;
  }

  &_icon {
    display: flex;
    margin-right: 8px;
  }

  &_title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;

  }

  &_body {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    text-wrap: nowrap;
    font-weight: 400;

  }
}