@import "../../assets/css/var";

.dashboard {
  padding: $gap;
  border-radius: 12px;
  display: flex;
  grid-gap: $gap;
  flex-direction: column;
}

.ac-summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}


.ac-recent {
  padding: $gap;
  background: $background;
  border-radius: 12px;
  min-height: 300px;
  display: flex;
  grid-gap: $gap;
  // justify-content: space-between;

  &_transaction {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin-bottom: $gap;
  }

  &_earn_points {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 380px;

    &_wrapper {
      padding: $gap;
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      width: 100%;
      border-radius: 8px;
      box-shadow: 0px 12px 24px 0px #0000000D;

    }

    &_item {
      background: $background;
      height: 54px;
      display: flex;
      flex-direction: row;
      padding: 8px 16px;
      border-radius: 8px;
      box-shadow: 0px 5px 10px 0px #0000001A;
      grid-gap: 12px;
      width: 100%;
      max-width: 380px;

      .icon {
        background: #428F273D;
        color: $white-100;
        height: 38px;
        width: 38px;
        font-size: $xxl-font-size;
        font-weight: 600;
        justify-content: center;
        border-radius: 8px;
      }

      .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          &:nth-child(1) {
            color: $link-color;
            font-family: Roboto;
            font-size: $lg-font-size;
            line-height: 21px;
            text-align: left;

          }

          &:nth-child(2) {
            color: $text-color;
            font-size: $base-font-size;
          }
        }
      }
    }
  }
}


@media screen and (max-width: $lg-width) {
  .ac-summary {
    grid-template-columns: 1fr 1fr;
  }

  .ac-recent {
    flex-direction: column;
  }
}

@media screen and (max-width: $sm-width) {
  .ac-summary {
    grid-template-columns: 1fr;
  }

}