@import "../../assets//css/var";

$psw-meter-background: #fff;
$psw-meter-too-weak: rgb(210, 10, 10);
$psw-meter-weak: rgb(179, 179, 29);
$psw-meter-good: rgb(71, 161, 71);
$psw-meter-very-strong: rgb(14, 106, 14);
$psw-meter-strong: rgb(8, 150, 8);

.password-strength {
  position: relative;
  &-meter {
    align-items: center;
    background: var($psw-meter-background);
    display: flex;
    height: 7px;
    padding: 0 1px 0;

    &__block {
      display: flex;
      height: 5px;
      transition: all 1s ease;

      &.too-weak {
        background: var($psw-meter-too-weak);
        width: 20%;
      }

      &.weak {
        background: var($psw-meter-weak);
        width: 40%;
      }

      &.good {
        background: var($psw-meter-good);
        width: 60%;
      }

      &.strong {
        background: var($psw-meter-strong);
        width: 80%;
      }

      &.very-strong {
        background: var($psw-meter-very-strong);
        width: 100%;
      }
    }
  } 
}

.password-pop-up {
  background: $background;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 10px 12px rgba(128, 128, 128, 0.65);
  padding: 14px 9px 14px;
  position: absolute;
  width: 100%;
  text-align: left;
  z-index: 6;
  top: -24px;

  &__strength {
    height: 32px;

    &-title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .too-weak,
  .weak,
  .good,
  .strong,
  .very-strong {
    font-weight: bold;
    font-size: 16px;
   }

  .too-weak {
    color: $psw-meter-too-weak;
  }

  .weak {
    color: $psw-meter-weak;
  }

  .good {
    color: $psw-meter-good;
  }

  .strong {
    color: $psw-meter-strong;
  }

  .very-strong {
    color: $psw-meter-very-strong;
  }
}
