@import "../../assets/css/var";


.progress-bar {
  height: 100%;

  &-wrapper {

    // min-height: 12px;

    width: 100%;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 16px;

  }

  &.primary {
    width: 20%;
    height: 16px;
    background-color: #4caf50;
    text-align: center;
    height: 100%;
    // line-height: 30px;
    // color: white;
  }
}