@import "../../assets/css/var";

.exchange {
  padding: $gap;

  &_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $gap;
  }

  &-tab_wrapper {
    display: flex;
    align-items: center;
    background: $background;
    width: fit-content;
  }

  &-tab {
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    margin: $md-gap;
    font-weight: 600;
    color: $link-color;

    &.active {
      background: $btn-bg;
      color: $white-100;

      &:hover {
        background: $btn-active-bg;
      }
    }

    &:hover {
      background: $container-bg;
    }
  }
}

.swap_wrapper {
  background: $background;
  max-width: 600px;
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  border-radius: 24px;
  box-shadow: 0px 5px 21px -5px #cdd1e1;

  .swap_rate {
    display: flex;
    justify-content: center;
    font-size: $lg-font-size;
  }

  .swap_user_balance {
    display: flex;
    justify-content: right;
    font-size: $base-font-size;
    font-weight: 600;
  }
}
.empty-data{
  text-align: center;
}
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-logo {
  width: 32px;
  height: 32px;
  // margin-right: 8px;

}

@media screen and (max-width: $lg-width) {
  .exchange {

    &_wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}


@media screen and (max-width: $sm-width) {
  .exchange_wrapper {
    grid-template-columns: 1fr;
  }
}