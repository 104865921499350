@import "../../assets/css/var";

.sidebar {
  &-wrapper {
    z-index: 11;
    width: 0;
    position: fixed;
    top: $nav-height;
    right: 0;
    height: calc(100svh - 60px);
    background: $background;
    display: block;
    transition: width 0.45s ease;
    box-shadow: 0px 5px 21px -5px #cdd1e1;

    .bottom {
      max-height: (80px + 16px);
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0px;

      grid-gap: 2px;
      padding: 8px 8px;
    }
  }

  &-content {
    display: flex;
    grid-gap: 2px;
    flex-direction: column;
    padding: 16px 8px;
    width: 208px;
    height: calc(100% - (80px + 16px));

    flex-wrap: nowrap;
    flex-direction: column;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-item {
    height: 48px;
    border-radius: 8px;

    // transition: background 0.25s ease, color 0.25s ease;
    &:hover,
    &.active {
      background: $btn-bg;

      a,
      svg,
      span {
        color: $white-100;
      }

      svg.svg-solid {
        path {
          fill: $white-100;
        }
      }
    }

    span,
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 8px;
      padding: 4px 8px;
      height: 100%;
      color: $text-color;
      border-radius: 8px;

      white-space: nowrap;


      &.active {
        background: $btn-bg;
        color: $white-100;

        svg,
        span {
          color: $white-100;
        }

        svg.svg-solid {
          path {
            fill: $white-100;
          }
        }
      }
    }
  }
}

//control logo size, a element
.mini-logo {
  width: 43px;
  overflow: hidden;
}

.show {
  width: 208px;
}

@media screen and (min-width: $md-width) {
  .sidebar {
    &_wrapper {
      display: none;
    }
  }
}