@import "../../assets/css/var";

// === badge ===
.badge {


  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 14px;
  color: #ffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: $sm-font-size;
  width: fit-content;


  svg {
    color: white;
  }
}

// === badge primary ===
.badge-primary {
  background: $btn-bg;
}

// === badge secondary ===
.badge-secondary {
  background: #3d8bfd;
}

// === badge success ===
.badge-success {
  background: #53b96a;
}

// === badge danger ===
.badge-danger {
  background: #e35d6a;
}

// === badge warning ===
.badge-warning {
  background: #e8b006;
}

// === badge default ===
.badge-default {
  background: #bdc4ca;

}