.copyable-text-field {
  // align-items: center;
  // background: var(--input-background-color);
  background: inherit;
  border: none;
  // border-radius: calc(var(--gap) * 0.66);
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0;
  .custom_input_group {
    margin: 0 !important;
  }

  .copyable-text-field {
    padding: 0;
  }
}
