.session_expired_modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
  z-index: 100000;
  top: 0;

  .session_expired {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    height: 145px;
    background: #e8e2e2;
    padding: 3rem 2rem;
    border-radius: 4px;
    font-size: 18px;

    .icon {
      svg {
        fill: rgba(168, 20, 20, 0.337);
        height: 80px;
        width: 80px;
      }
    }
  }
}