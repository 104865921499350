@import "../../assets//css/var";

.input-addon {
  min-width: 48px;
  //   height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: $input-border;
  //   border-left: none;
}

.addon-left {
  border-right: none;
}
.addon-right {
  border-left: none;
}
