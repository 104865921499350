@import "../../assets/css/var";

.exchange-card {
  // padding: $gap;
  box-shadow: 0px 5px 21px -5px #cdd1e1;
  position: relative;
  background: $background;
  display: flex;
  grid-gap: 12px;
  flex-direction: column;
  padding: 8px;
  cursor: pointer;
  border-radius: 12px;

  max-width: 380px;
  width: calc(100%);

  // width: calc(100%);

  &:hover {
    background: $container-bg;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 12px;

    // justify-content: center;
    align-items: center;
    grid-gap: $md-gap;

    background: $background;
    position: relative;
    height: 145px;
    width: calc(100%);
    padding: 8px;
    color: $white-100;
  }

  &_points {
    font-family: Nunito Sans;
    font-size: $lg-font-size;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
    z-index: 2;
    margin-top: $gap;
  }

  &_price {
    font-family: Nunito Sans;
    font-size: $lg-font-size;
    font-weight: 700;
    line-height: 25px;
    text-align: left;

  }

  &_amount {
    position: absolute;
    font-family: Nunito Sans;
    font-size: $base-font-size;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
    right: 8px;
    bottom: 8px;
  }

  &_price {
    z-index: 2;
  }

  &_logo {
    position: absolute;
    z-index: 0;
    bottom: 8px;
    left: 8px;
  }


  &.exchange-buy-card {
    .exchange-card_wrapper {
      background-image: url("../../../public/img/buy.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &.exchange-sell-card {
    .exchange-card_wrapper {
      background-image: url("../../../public/img/sell.svg");
      background-repeat: no-repeat;
      background-size: cover;

    }
  }

}

@media screen and (max-width: $lg-width) {}