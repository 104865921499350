$xl-font-size: 20px;
$xxl-font-size: 24px;
$lg-font-size: 18px;
$base-font-size: 16px;
$sm-font-size: 14px;

$gap: 24px;
$base-gap: 16px;
$md-gap: 8px;
$sm-gap: 4px;

$xxl-width: 1400px;
$xl-width: 1200px;
$lg-width: 992px;
$md-width: 768px;
$sm-width: 576px;
$xs-width: 480px;

$border-radius: 12px;

$primary-color: #4b49ac;
$primary-color-active: #3c3aad;
$primary-color-disable: #8483b1;

$font-family: "Nunito Sans", sans-serif;
$font-family-2: "Roboto", sans-serif;

$btn-bg: $primary-color;
$btn-active-bg: $primary-color-active;
$btn-disabled-bg: $primary-color-disable;
$white-100: #ffff; //for button text
$nav-height: 60px;

// use Root Var
$text-color: var(--text-color);
$background: var(--background);
$sub-menu-hover: var(--sub-menu-hover);
$link-color: var(--link-color);
$input-gb: var(--background);
$input-border: var(--input-border);
$link-color-active: var(--link-color-active);
$container-bg: var(--container-bg);

// === Light Theme ===

// $text-color: #363333dd;
// $background: #ffff;
// $sub-menu-hover: #f5f7ff;
// $link-color: #4b49ac;
// $input-gb: $background;
// $input-border: 1px solid gray;
// $link-color-active: #69669f;
// $container-bg: #f5f7ff;

// === End Light Theme ===

// === Dark Theme ===

// $background: #292433;
// $sub-menu-hover: #f5f7ff2a;
// $text-color: #ffff;
// $link-color: #bcbcf2;
// $input-gb: #0000004a;
// $sidebar-gb: $background;
// $input-border: 1px solid gray;
// $link-color-active: #69669f;
// $container-bg: #393d50;

// === End Dark Theme ===
