@import "../../assets/css/var";

.referrals {
  padding: $gap;


  &-wrapper {
    background: $background;
    padding: $gap;
    border-radius: $gap;
    flex-direction: column;
    grid-gap: $gap;

    h3 {
      color: $link-color;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    grid-gap: $gap;
    justify-content: space-between;
  }

  //
  &-content_left {
    max-width: 600px;
    display: flex;
    flex-direction: column;

    p {
      font-size: $lg-font-size;
    }

    &_item {
      &_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;

        grid-gap: $gap;
        padding: $gap;
      }

      //item 

      display: flex;
      grid-gap: $md-gap;

      &_icon {
        width: 42px;
        height: 42px;
        background: rgba(238, 243, 235, 1);
        padding: 4px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
        }
      }

      &_details {
        display: flex;
        flex-direction: column;

        span {
          &:nth-child(1) {
            font-weight: 600;
          }

          &:nth-child(2) {
            font-weight: 600;
            font-size: $base-font-size;
          }
        }
      }


    }
  }

  //
  &-content_right_item {

    &_wrapper {
      display: flex;
      flex-direction: column;
      grid-gap: $gap;
    }

    display: flex;
    flex-direction: row;
    grid-gap: $md-gap;

    &_icon {
      display: flex;
      align-items: center;
    }

    &_details {
      display: flex;
      flex-direction: column;
      grid-gap: 4px;

      span {
        &:nth-child(1) {
          color: $link-color;
          font-size: $lg-font-size;
        }

        &:nth-child(2) {
          font-size: $lg-font-size;
        }
      }
    }
  }

  //referral link
  &_link {
    max-width: 520px;
  }
}

@media screen and (max-width: $md-width) {
  .referrals-content {
    flex-direction: column;
  }
}

@media screen and (max-width: $xs-width) {
  .referrals-content_left_item_wrapper {
    grid-template-columns: 1fr;
  }
}