@import "../../assets/css/var";

.checkbox {
  display: flex;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] ~ .input-helper::before {
    content: " ";
    height: 16px;
    width: 16px;
    background: $background;
    display: flex;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    border: 2px solid $primary-color;
  }
  input[type="checkbox"]:checked ~ .input-helper::before {
    content: "\2714";
    background: $primary-color;
    color: white;
    // border: unset;
  }
}
