@import "../../assets/css/var";

.my-task {
  padding: $gap;
  flex-direction: column;

  &_wrapper {
    background: $background;
    padding: $gap;
  }

  h3 {
    margin-bottom: $gap;
  }

  &-wrapper {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // flex-wrap: wrap;
    grid-gap: 16px;
  }
}

@media screen and (max-width: $lg-width) {
  .task {
    &-wrapper {
      grid-template-columns: 1fr 1fr;

    }
  }
}

@media screen and (max-width: $sm-width) {
  .task {
    &-wrapper {
      grid-template-columns: 1fr;

    }
  }
}