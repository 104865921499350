@import "../../assets/css/var";
 
.features{
  position: relative;
  background: $background;
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  &-wrapper{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    display: grid; 
    height: 100%;
    width: 100%;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 24px;
    
    
   }
   &-item{
    max-height: 256px;
    background: $container-bg;
    color: $text-color;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    padding: 24px;
    border-radius: 4px;
    &_icon{
      display: flex;
      justify-content: center;
      align-items: center; 
    }
    &_content{
      flex-direction: column;
      display: flex;
      grid-gap: 24px;
      p{
        font-size: $lg-font-size;
      }
      h2{
        color: $link-color;
      }

    }
   }
}

@media screen and (max-width: $md-width) {

  .features{ 
    // background: $container-bg;
    &-wrapper{
  grid-template-columns: 1fr;
    }
    &-item_content{
      justify-content: center;
    }
  }
}

@media screen and (max-width: $lg-width) {

  .features{
    // padding: 16px;
    
    &-wrapper{
      grid-gap: 16px;
      padding: 0px 8px;
    }
    &-item{
        padding: 8px;
        
    }
  }
}