@import "../../assets/css/var";
.account-menu {
  &_wrapper {
    transition: 0.3s ease-in-out;
    position: relative;
  }
  &_icon {
    background: #f2f3f7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    svg {
      position: relative;
      bottom: -5px;
      color: #9da5ac;
    }
  }
  &_dropdown {
    width: 186px;
    background: $background;
    position: absolute;
    right: 0;
    top: 50px;
    height: auto;
    box-shadow: -1px 12px 20px 12px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 10px 0px;
    &_item {
      color: $text-color;
      font-size: $base-font-size;
      height: 48px;
      display: flex;
      align-items: center;
      &:hover {
        background: $sub-menu-hover;
      }
      a,
      span {
        display: flex;
        align-items: center;
        grid-gap: 4px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 10px;
      }
    }
  }
}

.logout {
  color: rgb(222, 93, 47);
  svg {
    color: rgb(222, 93, 47);
  }
}
