@import "../../assets/css/var";

.summary-card {
  height: 150px;
  // max-width: 300px;
  border-radius: 12px;
  padding: $gap;

  color: $white-100;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  &_title {
    // font-family: Inter;
    font-size: $base-font-size;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
  }

  &_details {
    font-size: $xxl-font-size;
    font-weight: 700;
    line-height: 29px;
    text-align: left;

  }

  &_description {
    font-size: $base-font-size;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
  }
}