@import "../../assets/css/var";

.watch-youtube-wrapper {
  display: flex;
  grid-gap: $gap;
  flex-direction: column;
  max-width: 800px;
}

.watch-youtube {
  padding: $gap;
}

// youtube card
.watch-youtube-card {
  &__wrapper {

    // width: 300px;
    max-height: 176px;
    max-width: 258px;

    &.main-watch {
      max-height: 400px;
      max-width: 540px;

    }

    position: relative;
    background: $background;
    padding: 8px;
    box-shadow: 0px 5px 21px -5px #cdd1e1;
    border-radius: 12px;

  }

  &__thumbnail {

    img {
      width: calc(100%);
      // border-radius: 12px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    justify-content: space-between;

    &__details {
      display: flex;
      font-weight: 600;
      flex-direction: column;

      .timer {
        color: $link-color;
      }
    }

    &__points {
      display: flex;
      flex-direction: column;

      span {
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        grid-gap: 4px;
        font-weight: 600;
      }

    }
  }

}

//watch main section
.watch-youtube {
  &__main {
    display: flex;
    flex-direction: row;
    grid-gap: $gap;
  }

  &__items__wrapper {
    display: flex;
    // flex-direction: column;
    grid-gap: 8px;
  }
}

.yt {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

// shorten text with 3 dots
.txs {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}