@import "../../assets/css/var";

.about {
  min-height: 380px;
  background: $background;
  display: flex;
  justify-content: center;
  width: 100%;

  &-wrapper {
    display: flex;
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 24px;
    align-items: center;
    justify-content: space-around;
    padding: 8px;

  }

  &-content {
    &_left {
      color: $text-color;
      height: 280px;
      max-width: 400px;
      background: $container-bg;
      position: relative;
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .about-card {
        background: $background;
        border-radius: 12px;
        height: 100%;
        max-width: 420px;
        width: 100%;
        overflow: hidden;

        img {
          width: calc(100%);
        }

        &_details {
          font-size: $lg-font-size;
          padding: 8px;

          .points {
            margin-left: 16px;
            color: $link-color-active;
            font-weight: 600;
          }
        }
      }
    }

    &_right {
      color: $text-color;
      display: flex;
      justify-content: center;
      grid-gap: 24px;
      flex-direction: column;

      p {
        font-size: $xxl-font-size;
        max-width: 580px;
      }

      h2 {
        color: $link-color;
      }
    }
  }



}

@media screen and (max-width: $md-width) {

  .about {

    &-wrapper {
      flex-direction: column;
    }

    &-content {
      &_right {
        p {
          font-size: $lg-font-size;

        }

      }
    }
  }
}