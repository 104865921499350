@import "../../assets//css/var";

.input-group {
  position: relative;
  max-height: 48px;
  display: flex;

  &.select {
    cursor: pointer;

    &>select {
      padding: 10px 24px;
      appearance: none;
      cursor: pointer;
    }

    .select-addon {
      background: #d1c0c01a;
    }

    .options_wrapper {
      list-style: none;
      z-index: 20;
      position: absolute;
      top: 100%;
      background: $input-gb;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      padding: 8px 16px;
      border: $input-border;
      cursor: pointer;
      display: none;

      &.show {
        display: block;
      }

      li {
        height: 24px;
        padding-left: 4px;
        padding-right: 4px;
        font-size: $base-font-size;


        &:hover {
          background: $btn-bg;
          color: $white-100;
        }
      }
    }
  }
}

// .input-ol {

//   height: 48px;
//   width: 100%;
//   font-size: $base-font-size;
//   border: $input-border;
//   background: $input-gb;
//   color: $text-color;

//   &:focus {
//     outline: none;
//     border: 1px solid rgb(8, 8, 248);
//   }

//   &:disabled {
//     background-color: $sub-menu-hover;
//   }
// }

// .input-group.error {
//   input {
//     border: 1px solid rgb(214, 8, 8);
//   }
// }

// .input-error {
//   display: flex;
//   position: absolute;
//   bottom: -16px;
//   color: red;
//   font-size: 12px;
//   font-weight: 600;
//   font-family: $font-family-2;
//   padding: 0px 24px;
// }