@import "../../assets/css/var";
.pg-alerts {
  position: fixed;
  right: calc($gap);
  top: calc($gap * 3);
  z-index: 100005;
  grid-gap: 0.5rem;
  display: flex;
  flex-direction: column;
}
