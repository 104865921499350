.form__cros-icon {
  width: 30px;
  position: absolute;
  top: 0px;
  right: 8px;
  cursor: pointer;
}

.login-2fa {
  position: relative;
}
