.code-verification {
  width: 100%;

  &__label {
    color: #52679e;
    font-size: 16px;
    margin-bottom: 12px;
  }

  &__block {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__content {
      // margin-right: 18px;
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    &-item {
      align-items: center;
      background: #e7ecf8;
      border-radius: 4px;
      color: rgb(101, 94, 94);
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: bold;
      height: 50px;
      justify-content: space-around;
      margin: 0 3px;
      width: 50px;
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(3) {
        margin-right: 9px;
      }
      &:nth-child(4) {
        margin-left: 9px;
      }
      &:last-child {
        margin-right: 0;
      }
      &-placeholder {
        color: rgb(58, 62, 58);
        font-weight: normal;
        font-size: 18px;
        &-border {
          background: #e7ecf8;
          border: 2px solid #90bcfe;
          border-radius: 4px;
        }

        &-border-hidden {
          border-bottom: none;
        }
      }
    }
  }

  &__input {
    height: 50px;
    margin-top: -50px;
    width: 100%;

    input {
      height: 50px;
      opacity: 0;
      width: 100%;
    }
  }

  &__paste {
    border: 1px solid rgba(61, 120, 237, 0.669);
    border-radius: 4px;
    color: rgb(61, 59, 59);
    cursor: pointer;
    display: flex;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    padding: 11px 16px;

    &:hover {
      background: #eff2f9;
      color: rgba(28, 48, 161, 0.867);
      transition: 0.2s;

      svg {
        path {
          fill: rgba(18, 10, 88, 0.867);
          transition: 0.2s;
        }
      }
    }

    svg {
      margin-right: 7px;
    }
  }
}

@media (max-width: 768px) {
  .code-verification {
    &__wrapper {
      &-item {
        height: 40px;
        margin: 0 0 0 8px;
        width: 40px;
      }
    }

    &__input {
      height: 56px;

      input {
        height: 40px;
      }
    }

    &__paste {
      font-size: 12px;
      margin-left: 8px;
      margin-top: -6px;
    }

    &__block {
      &__content {
        margin-right: unset;
      }
    }
  }
}
