@import "../../assets//css/var";

.password-strength-tip {
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  text-align: left;

  &-title {
    margin-top: 8px;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    color: $text-color;
  }

  &-text {
    color: $text-color;
    opacity: 0.72;
    padding-bottom: 2px;
    font-size: 14px;
  }
}
