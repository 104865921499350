@import "../../assets/css/var.scss";

.history_pagination {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 16px 0;

  p {
    font-size: 1rem;
    margin: 0;
    padding: 8px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 calc(var(--gap) * 2.5);
    outline: none;
    padding: 0;
    //
    align-items: center;
    display: flex;
    justify-content: center;
    width: 32px;
    height: 32px;

    background: $background;
    border: $input-border;
    border-radius: 4px;

    &:hover {
      border: 2px solid rgb(129, 129, 229);
    }

    &:disabled {
      cursor: default;
      border: $input-border;
    }

    svg {
      fill: var(--icon);
    }
  }
}