@import "../../assets//css//var";

.pg-dashboard {
  min-height: calc(100vh - 60px);
  height: 100%;
  display: flex;
  flex-direction: row;
  top: $nav-height;
  position: relative;

  .pg-main {
    width: calc(100% - 256px);
    position: absolute;
    right: 0;
    transition: width 0.45s ease;
  }
}

// no left sidebar so pg-main class will 
// take the available space
@media screen and (max-width: $md-width) {
  .pg-main {
    width: calc(100%) !important;
  }
}