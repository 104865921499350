//Nav

@import "../../assets/css/var.scss";
 
.pg-landing{
    margin-top: 60px;
}

.ads-g{
    height: 180px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    background: $background;
    color: $text-color
}