@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap");
@import "./assets/css/var";
// === Global ===
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Nunito Sans", sans-serif;
  // scrollbar
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1d3;
  }
  ::-webkit-scrollbar {
    height: 12px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
}

:root {
  // transition: background 0.3s ease;
  --background: #ffff;
  --text-color: #363333dd;
  --sub-menu-hover: #f5f7ff;
  --link-color: #4b49ac;
  --input-gb: $background;
  --input-border: 1px solid gray;
  --link-color-active: #69669f;
  --container-bg: #f5f7ff;
  --icon: var(--text-color);
}

[data-theme="dark"] {
  // transition: background 0.3s ease;
  --background: #292433;
  --sub-menu-hover: #f5f7ff2a;
  --text-color: #e9e5e5;
  --link-color: #bcbcf2;
  --input-gb: #0000004a;
  --sidebar-gb: $background;
  --input-border: 1px solid gray;
  --link-color-active: #69669f;
  --container-bg: #393d50;
  --icon: var(--text-color);
}

// [data-theme="light"] {
//   transition: background 0.3s ease;
//   --background: #ffff;
//   --text-color: #363333dd;
//   --sub-menu-hover: #f5f7ff;
//   --link-color: #4b49ac;
//   --input-gb: $background;
//   --input-border: 1px solid gray;
//   --link-color-active: #69669f;
//   --container-bg: #f5f7ff;
// }

body {
  background: $container-bg;
}

a {
  text-decoration: none;
  color: inherit;
}

.fit {
  width: fit-content;
}

.row {
  display: flex;
  flex-direction: row;
}

.mt24{
  margin-top: 24px;
}
.mt8{
  margin-top: 8px;
}
.mt16{
  margin-top: 16px;
}
.mb16{
  margin-bottom: 16px;
}

.col {
  display: flex;
  flex-direction: column;
}

.i24 {
  width: 24px;
  color: $text-color;
}

.i32 {
  width: 32px;
  color: $text-color;
}

.x100 {
  width: 100%;
}
.x50 {
  width: 50%;
}
.y100 {
  height: 100%;
}
.y50 {
  width: 50%;
}

.br12 {
  border-radius: 12px !important;
}
.br8 {
  border-radius: 8px !important;
}
.br4 {
  border-radius: 4px !important;
}
.br0 {
  border-radius: 0px !important;
}
.white-100 {
  color: #ffff !important;
}
.text-center {
  text-align: center;
}

.flex{
  display: flex;
}
.flex-row{
  flex-direction: row;
}
.flex-column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
.space-between {
  justify-content: space-between;
}

.container {
  margin-top: calc($md-gap * 2);
  margin-left: $gap;
  margin-right: $gap;
  padding: $md-gap;
  background: $background;
  color: $text-color;
}

.pg-login {
  margin-top: calc($gap * 5);
  background: $background;
  color: $text-color;
  max-width: 480px;
  width: 100%;
  height: auto;
  padding: $gap;
  display: flex;
  flex-direction: column;
  grid-gap: $gap;
  border-radius: 12px;
  img {
    max-width: fit-content;
  }
}

.bg-trans {
  background: transparent;
}
.l-intro {
  font-weight: 500;
  font-size: 14px;
  color: $text-color;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: $text-color;
  }
}
.l-link {
  text-decoration: none;
  color: $link-color;
}

.cx-center {
  max-width: $xxl-width;
  margin-left: auto;
  margin-right: auto;
}
.center{
  margin-left: auto;
  margin-right: auto;
}

.cr-table_warpper {
  overflow-x: auto;
}

.c-logo {
  img {
    max-height: 40px;
  }
}

 

@media screen and (max-width: $xs-width) {
  .pg-login {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (min-width: $xs-width) {
  .pg-login {
    margin-left: auto;
    margin-right: auto;
  }
}
