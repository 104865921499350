@import "../../assets/css/var";

.radio {
  display: flex;
  width: fit-content;

  input[type="checkbox"] {
    display: flex;
    position: relative;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
  }

  input[type="checkbox"]::before {
    content: " ";
    height: 16px;
    width: 16px;
    background: $background;
    display: flex;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 50px;
    border: 2px solid $primary-color;
  }
  &-primary {
    input[type="checkbox"]:checked:after {
      content: " ";
      position: absolute;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50px;
      background: $primary-color;
    }
  }
}
