.popup_screen {
  &_wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    z-index: 100001;
    top: 0;
    left: 0;
  }

  &_content {
    .popup_close {
      right: 0;
      top: 0;
      border-radius: 4px;
      position: absolute;
      display: flex;
      cursor: pointer;
      outline: none;
      border: none;
      background: #fff;

      svg {
        // fill: rgba(168, 20, 20, 0.498);
        fill: rgba(55, 48, 48, 0.498);
        height: 34px;
        width: 34px;
      }

      &:hover {
        background: rgba(224, 195, 195, 0.491);
      }
    }

    background: white;
    position: relative;
    min-height: 240px;
    min-width: 240px;
    padding: 2rem 1rem;
    border-radius: 0.5rem;
    // extra
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 10px #605e5ecb;
  }
}


@media screen and (max-width: 480px) {
  .popup_screen_content {
    // margin-right: auto;
    // margin-left: auto;
    width: calc(100% - 16px);
    min-width: unset;
  }
}