@import "../../assets/css/var";

// $table-shadow-2: 0px 3px 3px 0px rgba(206, 211, 216, 1);
$table-shadow: 0 5px 8px #e1e5ee;

$small-font-size: 1rem;
$normal-font-size: 1.125rem;
$larg-font-size: 1.5rem;

// for light mode
$tr-hover: #edf0f96f;

table.cr-table {
  font-family: "Roboto";
  text-align: left;
  width: 100%;
  position: relative;
  background-color: $background;
  border-collapse: collapse;
  // padding: 8px;
  .status {
    border-radius: 4px;
  }
  &_wrapper {
    padding: 1rem;
    margin: 8px;
    overflow: auto;
    box-shadow: $table-shadow;
    background-color: $background;
    // overflow-x: scroll;
  }

  thead {
    font-size: $sm-font-size;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    color: $text-color;
    font-weight: 600;

    transition: 0.3s ease all;
    height: 45px !important;
    padding-left: 8px;
    // box-shadow: $table-shadow;
    // -webkit-box-shadow: $table-shadow;
    // -moz-box-shadow: $table-shadow;
    background-color: $background;
    position: sticky;
    z-index: 2;
    top: -18px;

    th {
      padding: 0 8px;
    }
  }

  tbody {
    max-height: 200px;

    tr {
      height: 52px; // for small use 48

      td {
        padding: 0 8px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: $small-font-size;
        // color: #52679e;
        color: #88a4ef;
      }

      &:nth-child(odd) {
        // background: #f5f7ff;
        background: $sub-menu-hover;
      }

      &:nth-child(even) {
        background: $background;
      }

      &:nth-child(1) {
        // add 8px to first row to offset th shadow
        height: 48px + 8px;
      }
    }
  }

  // sticky header and fixd column 1
  &.fixed-h-c1 {
    th:nth-child(1),
    td:nth-child(1) {
      position: sticky;
      left: 0px; // -16;
    }

    th:nth-child(1),
    td:nth-child(1) {
      background-color: inherit; // require
    }

    th:nth-child(1) {
      z-index: 10;
      background-color: $background; // require
      //background-color: #4b4755;
      &::after {
        content: "";
        box-shadow: 0px 0px 10px black;
        position: absolute;
        right: 0;
        color: white;
        z-index: 20;
      }
    }
  }
}

//add shadow to right side of td
.r-td-shadow:after {
  box-shadow: 15px 0 15px -15px inset;
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  right: -15px;
  width: 15px;
}
