@import "../../assets/css/var";


.my-task-card {
  display: flex;
  box-shadow: 0px 5px 21px -5px #cdd1e1;
  // box-shadow: 0px 12px 24px 0px #0000000D;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: $container-bg;
  }

  &_icon {
    display: flex;
    flex-direction: column;
    margin-right: 8px;

    grid-gap: 4px;

    img {
      height: 38px;
      width: 38px;
    }
  }

  &_title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;

  }

  &_body {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    text-wrap: nowrap;
    font-weight: 400;

  }

  &_status {
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
  }
}