@import "../../assets/css/var";

.wallet {
  display: flex;
  flex-direction: column;
  grid-gap: $gap;
  max-width: $xxl-width;
  padding: $gap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;


  .row:nth-child(1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $gap;
  }

  .row:nth-child(2) {
    background: $background;
    border-radius: $border-radius;
    padding: $gap;
    min-height: 300px;
  }
}

// withdraw col
.withdraw {
  &-wrapper {
    position: relative;
    width: 100%;
    padding: $gap;
    border-radius: $border-radius;
    background: $background;
  }

  &-content {
    background: $background;
    display: flex;
    grid-gap: $base-gap;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;


    input {
      width: 100%;
    }

    button {
      width: fit-content;
    }
  }
}

// deposit col
.deposit {
  &-wrapper {
    position: relative;
    background: $background;
    border-radius: $border-radius;
    padding: $gap;
    width: 100%;
  }

  &-content {
    background: $background;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: $md-gap;
    flex-direction: column;

    img {
      max-width: 150px;
      max-height: 150px;
    }

    input {
      text-align: center;
    }
  }
}

// History
.history {
  width: 100%;
}

.deposit {
  color: #24983f;
  font-weight: 600;
}

.withdraw {
  color: #c8303f;
  font-weight: 600;
}

.qrcode {
  padding: 8px;
  background: $white-100;
}

.withdraw_footer {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  align-items: center;

  span {
    font-size: $base-font-size;
    font-weight: 500;
  }
}


.wallets {
  &_wrapper {
    background: $background;
    padding: 16px;
    color: $text-color;

  }

  color: $text-color;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;



  display: flex;
  flex-wrap: wrap;
  background: $background;
  grid-gap: 16px;
  padding: 8px;

  .walletitem {
    &.active {
      background: $container-bg;
    }

    box-shadow: 0px 5px 21px -5px #cdd1e1;
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;

    &_content {
      display: flex;
      flex-direction: row;
      grid-gap: 8px;
      padding: 4px 8px;

      &__left {
        img {
          width: 40px;
          height: 40px;
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
      }

    }

  }
}

.badge.token-name {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 12px
}

@media screen and (max-width: $sm-width) {
  .wallet {
    .row:nth-child(1) {
      grid-template-columns: 1fr;
    }
  }
}