@import "../../assets/css/var";

.footer{
  color: $text-color;
  background: $background;
  min-height: 200px;
  padding: 16px;
  margin-top: 4px;
  &-wrapper{
    max-width: 1400px;
    height: 100%;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   
  }

  &-item{
    min-height: 160px;
    h2{
      margin-bottom: 24px;
      // color: $link-color;
    }
    ul{
      list-style: none;
      font-size: $lg-font-size;
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      a{
        color: $link-color;
        &:hover{
          color: $link-color-active;
        }
      }
    }
    &:nth-child(4){
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &-sm{
    text-align: center;
    color: $text-color;
    height: 20px;
  }
}

 
 
.social-links { 
  flex-direction: row !important;
  grid-gap: 8px;
  li {
    cursor: pointer;

    svg {
      fill: #e8c984;
    }
    &:hover {
      svg {
        fill: #e29f0e;
      }
    }
  }
}

@media screen and (max-width: $lg-width) {
  .footer{ 
    &-wrapper{ 
     grid-template-columns: 1fr 1fr ;
     
    }
    &-item{
      &:nth-child(4){
        display: flex;
        align-items: unset;
        flex-direction: column;
      }
    }
  }  
}

@media screen and (max-width: $xs-width) {
  .footer{ 
    &-wrapper{ 
     grid-template-columns: 1fr;
     
    }
    &-item{
        display: flex;
        align-items: unset;
        flex-direction: column;
    }
  }  
}
 