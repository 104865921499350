@import "../../assets/css/var";
.settings {
  padding: 24px;
  &_content {
    background: $background;
    width: 100%;
    min-height: 300px;
    padding: $gap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $gap;
    .col {
      grid-gap: 8px;
    }
  }
}

.g-auth_role {
  display: flex;
  //   justify-content: space-around;
  grid-gap: $gap * 2;
  flex-direction: row;
  .g-auth_content {
    display: flex;
    margin: 8px;
    grid-gap: 8px;
  }
  .role_content {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    grid-gap: 8px;
    margin: 8px;
  }
}

@media screen and (max-width: $md-width) {
  .settings { 
    &_content { 
      grid-template-columns: 1fr;
    }
  }
}