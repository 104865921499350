//Nav

@import "../../assets/css/var.scss";

.nav {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 60px;
  z-index: 100;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 24px;
  background: $background;
  box-shadow: 0px 5px 21px -5px #cdd1e1;
  font-size: $base-font-size;

  .content {
    &-left {
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: $gap;
        cursor: pointer;

        svg {
          fill: #494c4f;
        }
      }
    }

    &-center {
      list-style: none;
      display: flex;
      flex-direction: row;
      grid-gap: $base-gap;

      li {
        color: $text-color;
        cursor: pointer;

        &:hover {
          color: $link-color-active;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 8px;

      ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        grid-gap: 8px;
      }
    }
  }
}

.right-menu-icon {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: $md-width) {
  .nav {
    // position: fixed;
    // height: 68px;
    top: 0px;

    .content-center {
      display: none;
    }

    .content-right {
      .auth-btn_wrapper {
        display: none;
      }
    }

    .right-menu-icon {
      display: block;
    }
  }
}

.c-logo {
  display: flex;
  align-items: center;
  grid-gap: 8px;

  span {
    font-size: 24px;
    font-weight: bold;
    // color: $btn-active-gb;
  }
}

.auth-btn_wrapper {
  max-width: fit-content;
  display: flex;
  grid-gap: 8px;
}

[data-theme="dark"] {
  .btn-primary.outline {
    color: #e2e2eb;
  }
}

// Toggle theme button
.theme-mode {
  margin-right: 12px;
}

.light,
.dark {
  color: #88a4ef;
}

[data-theme="light"] {
  .theme-mode {
    .light {
      display: none;
    }
  }
}

[data-theme="dark"] {
  .theme-mode {
    .dark {
      display: none;
    }
  }
}

.menu-toggle {
  margin-right: 8px;
  cursor: pointer;
}