@import "../../assets//css//var";

.terms-pg {
  &_wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  margin: 60px 8px;
  padding: 24px 8px;

  font-size: $lg-font-size;

  h5 {
    font-weight: 600 !important;
    font-size: $lg-font-size;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 18px;
  }

  ul {
    margin-left: calc($gap * 2);
  }

  a {
    color: $link-color;
  }
}