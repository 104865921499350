@import "../../assets/css/var";
// === btn ===
.btn {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  padding: 8px 24px;
  height: 40px;
  color: #ffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $lg-font-size;
  &-small {
    height: 32px;
    padding: 4px 16px;
    font-size: $base-font-size;
  }
  &-medium {
    height: 40px;
    padding: 8px 24px;
    font-size: $lg-font-size;
  }
  &-large {
    height: 48px;
  }
  &-xl {
    height: 56px;
  }
  &-xl,
  &-large {
    padding: 12px 32px;
    font-size: $lg-font-size;
    border-radius: 8px;
  }
  svg {
    color: white;
  }
}

// === btn primary ===
.btn-primary {
  background: $btn-bg;

  &:hover {
    background: $btn-active-bg;
  }
  &:disabled{
    background: #8483b1;
  }
  &.outline {
    background: $background;
    border: 2px solid $btn-bg;
    color: $btn-bg;
    &:hover {
      background: $sub-menu-hover;
    }
    // &:disabled{

    // }
  }
}

// === btn secondary ===
.btn-secondary {
  background: #3d8bfd;
  &:hover {
    background: #0d6efd;
  }
  &:disabled {
    background: #90bcfe;
  }
  &.outline {
    background: $background;
    border: 2px solid #3d8bfd;
    color: #3d8bfd;
    &:hover {
      background: #e7f1ff;
    }
    &:disabled {
      color: #90bcfe;
      border: 2px solid #90bcfe;
    }
  }
}

// === btn success ===
.btn-success {
  background: #53b96a;
  &:hover {
    background: #28a745;
  }
  &:disabled {
    background: #9cd7a9;
  }
  &.outline {
    background: $background;
    border: 2px solid #53b96a;
    color: #53b96a;
    &:hover {
      background: #eaf6ec;
    }
    &:disabled {
      color: #9cd7a9;
      border: 2px solid #9cd7a9;
    }
  }
}

// === btn danger ===
.btn-danger {
  background: #e35d6a;
  &:hover {
    background: #dc3545;
  }
  &:disabled {
    background: #efa2a9;
  }
  &.outline {
    background: $background;
    border: 2px solid #e35d6a;
    color: #e35d6a;
    &:hover {
      background: #fcebec;
    }
    &:disabled {
      color: #efa2a9;
      border: 2px solid #efa2a9;
    }
  }
}

// === btn warning ===
.btn-warning {
  background: #e8b006;
  &:hover {
    background: #b58905;
  }
  &:disabled {
    color: #adb5bd;
    background: #ffecb2;
  }
  &.outline {
    background: $background;
    border: 2px solid #e8b006;
    color: #e8b006;
    &:hover {
      background: #fff9e6;
    }
    &:disabled {
      color: #ffcd39;
      border: 2px solid #ffcd39;
    }
  }
}

// === btn default ===
.btn-default {
  background: #bdc4ca;
  &:hover {
    background: #adb5bd;
  }
  &:disabled {
    background: #d9dde1;
  }
  &.outline {
    background: $background;
    border: 2px solid #bdc4ca;
    color: #bdc4ca;
    &:hover {
      background: #f7f8f8;
    }
    &:disabled {
      color: #d9dde1;
      border: 2px solid #d9dde1;
    }
  }
}
