@import "../../assets/css/var";

.left-sidebar {
  width: 256px;
  background: $background;
  // position: relative;
  z-index: 88;
  top: 60px;
  transition: width 0.45s ease;
  // display: flex;

  position: fixed;
  left: 0;
  height: calc(100svh - 60px);
  background: $background;
  box-shadow: 0px 5px 21px -5px #cdd1e1;

  &-wrapper {
    padding: 16px;
    transition: width 0.45s ease;

    position: relative;
    width: 256px;
    list-style: none;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    color: $text-color;

    li {
      transition: width 0.45s ease;
      height: 48px;
      align-items: center;
      font-size: 18px;
      border-radius: 8px;

      &.active,
      :hover {
        background: $btn-bg;
        color: $white-100;

        svg {
          path {
            fill: $white-100;
          }
        }

      }

      a {

        &.active {
          background: $btn-bg;
          color: $white-100;

          svg {
            path {
              fill: $white-100;
            }
          }

        }


        white-space: nowrap;
        height: 100%;
        display: flex;
        align-items: center;
        grid-gap: 8px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 8px;
        width: 100%;

        transition: width 0.45s ease;
        overflow: hidden;
        position: relative;

        span {
          display: inline-block;

        }
      }

      .i24 {
        min-width: 24px;
      }


    }

  }


}

//Small LeftSidebar
.left-sidebar.mini {
  width: 82px;

  .left-sidebar-wrapper {
    width: 82px;

    li {
      a {

        .i24 {
          margin: 4px;
        }

        span {
          opacity: 0;
        }

        &:hover {
          span {
            opacity: 1;
          }
        }
      }

      &:hover {
        width: 256px;
      }
    }
  }



}


.d-mini {
  width: calc(100% - 82px) !important;
}

// at this with we hide left sidebar
// for small screen
@media screen and (max-width: $md-width) {
  .menu-toggle {
    display: none;
  }

  .left-sidebar {
    display: none;
  }


}