.alert {
  position: relative;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;

  &-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}
